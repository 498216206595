<template>
  <div>
    <div class="NumberWisdom">
      <img src="../assets/images/数转智改.jpg" alt="" />
      <div class="button">
        <div v-if="!isLogin" @click="handleClickToLogin">立即登录</div>
        <div
          @click="handleClickApply"
          v-if="reviewStatus == '1' && loginType == 'qiye'"
        >
          立即申请
        </div>

        <div
          style="margin-top: 10px;"
          v-if="reviewStatus == '0' && loginType == 'qiye'"
          @click="handleClickConsequence"
        >
          结果查询
        </div>
        <div
          style="margin-top: 10px;"
          v-if="reviewStatus == '2' && loginType == 'qiye'"
          @click="handleClickApply"
        >
          内容填报
        </div>
      </div>
    </div>
    <div class="mainwidth">
      <div class="info">
        <h2>数转智改专项贷款申请计划</h2>
        <div class="limit">
          <p style="font-weight: bold;">可用额度</p>
          <a-divider></a-divider>
          <p style="margin-top: 10px">授信额度:100万-1000万</p>
          <!-- <p>参考利率:银行同期LPR</p> -->
          <p>贷款期限:1年-3年</p>
        </div>
        <div class="condition" style="margin-top: 20px;margin-bottom: 20px;">
          <p style="font-weight: bold;">贷款条件</p>
          <a-divider></a-divider>
          <p style="margin-top: 10px">
            哈尔滨区域内注册的有明确数字转型、智能化改造需求的制造型企业
          </p>
          <p>
            完成企业数字化转型改造方案
          </p>
          <p>具有稳定的一年以上经营历史和良好的信用记录</p>
        </div>
        <!-- <div class="condition" style="margin-top: 20px;">
        <p style="font-weight: bold;">担保方式</p>
        <a-divider></a-divider>
        <p>经开区担保公司信用担保</p>
      </div> -->
        <!-- v-if="policyList.length>0" -->
        <div
          class="support"
          style="margin-bottom: 20px;"
          v-if="policyList.length > 0"
        >
          <p style="font-weight: bold;">政策支持</p>
          <a-divider></a-divider>
          <p v-for="(item, index) in policyList" :key="index">
            <span style="margin-right: 10px;">{{ item.title }}</span
            ><a @click="handleClickDownload(item)">下载</a>
          </p>
          <!-- <p><span>《试点行业中小企业数字化改造路线图》</span><a>下载</a></p>
          <p><span>《国家产融合作试点域市典型案例集》</span><a>下载</a></p> -->
        </div>
        <!-- <div class="lj">
        <a-button
          type="primary"
          style="width: 200px;height: 40px;"
          @click="handleClickApply"
          >立即申请</a-button
        >
      </div> -->
      </div>
      <!-- 、弹窗 -->
      <a-modal
        v-model:visible="visibleShow"
        title="贷款所需材料"
        width="40%"
        @cancel="cancel"
        :maskClosable="false"
        :footer="null"
      >
        <div class="item">
          <p>融资诊断</p>
          <div class="button-wrap">
            <p @click="handleClickDiagnose" v-if="isFlag.if_diagnosis == '1'">
              立即诊断
            </p>
            <p @click="handleClickResult" v-if="isFlag.if_diagnosis == '0'">
              诊断结果
            </p>
          </div>
        </div>
        <div class="item">
          <p>数字化测评</p>
          <div class="button-wrap">
            <p @click="handleClickDigitization" v-if="isFlag.if_assess == '1'">
              立即测评
            </p>
            <p @click="handleClickTo" v-if="isFlag.if_assess == '0'">
              测评结果
            </p>
          </div>
        </div>
        <div class="item">
          <p>上传数字化转型方案</p>
          <div class="button-wrap">
            <p @click="handleClickUploading" v-if="!isFlag.option3">上传方案</p>
            <p v-if="isFlag.option3">已上传</p>
          </div>
        </div>
        <div class="success-title" v-if="reviewStatus == '0'">
          恭喜您已完成资料填写,请耐心等待,工作人员会在24小时内联系您。
        </div>
        <div class="success-text" v-if="reviewStatus == '0'">
          <p style="font-weight: bold;">
            如需进一步了解请拨打业务专线进行咨询:
          </p>
          <p>
            <span style="font-size: 16px;font-weight: bold;"
              >数字化转型服务:</span
            ><span style="margin-left: 10px">卢思冰</span
            ><span style="color: #1089ff;margin-left: 5px;">15765999441</span>
          </p>
          <p>
            <span style="font-size: 16px;font-weight: bold;">融资服务:</span>
            <span style="margin-left: 10px">李志伟</span
            ><span style="color: #1089ff;margin-left: 5px;">18245108077</span>
          </p>
          <p>
            <span style="font-size: 16px;font-weight: bold;">政策申请: </span
            ><span style="margin-left: 10px">侯惠宁</span
            ><span style="color: #1089ff;margin-left: 5px;">18745349632</span>
          </p>
        </div>
      </a-modal>
      <!-- 、弹窗 -->
      <a-modal
        v-model:visible="visibleUploadShow"
        :centered="true"
        title="数字化转型方案"
        @ok="handleOk"
        width="40%"
        :maskClosable="false"
      >
        <a-form
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          layout="vertical"
        >
          <a-form-item label="数字化转型方案">
            <a-textarea
              v-model:value="content"
              placeholder="方案介绍"
              style="min-height: 200px;"
            />
          </a-form-item>
          <a-form-item label="附件:">
            <a-upload
              name="file"
              :action="$uploadApiSB"
              :multiple="true"
              :before-upload="beforeUpload"
              @change="handleChange"
              accept=".pdf,.docx"
              :data="{
                files: fileList,
              }"
            >
              <a-button> <a-icon type="upload" /> 点击上传 </a-button>
            </a-upload>
          </a-form-item>
        </a-form>
      </a-modal>
      <!-- 结果查询弹窗 -->
      <!-- <a-modal
      v-model:visible="resultShow"
      :centered="true"
      title="结果查询"
      width="20%"
      :maskClosable="false"
      :footer="null"
    >
      <div style="display: flex;flex-direction: column;">
        <div class="result-item">
          <p>融资诊断</p>
          <p>
            <span v-if="isFlag.if_diagnosis == '0'">诊断结束</span
            ><span v-if="isFlag.if_diagnosis == '1'">待诊断</span>
          </p>
        </div>
        <div class="result-item">
          <p>数字化测评</p>
          <p>
            <span v-if="isFlag.if_assess == '0'">已测评</span
            ><span v-if="isFlag.if_assess == '1'">待测评</span>
          </p>
        </div>
        <div class="result-item">
          <p>数字化转型方案审核</p>
          <p>
            <span v-if="isFlag.option3">已上传</span
            ><span v-if="!isFlag.option3">待上传</span>
          </p>
        </div>
        <div class="result-item">
          <p>联系人</p>
          <div>
            <a-input
              style="width: 120px;margin-right: 5px;"
              size="small"
              v-model:value="person"
              placeholder="联系人"
            />
            <a-button size="small" type="primary" @click="handleClickPerson"
              >保存</a-button
            >
          </div>
        </div>
        <div class="result-item">
          <p>联系电话</p>
          <div>
            <a-input
              style="width: 120px;margin-right: 5px"
              size="small"
              v-model:value="phone"
              placeholder="联系电话"
            />
            <a-button size="small" type="primary" @click="handleClickPhone"
              >保存</a-button
            >
          </div>
        </div>
        <div class="text">
          恭喜您已完成资料填写,请耐心等待,工作人员会在24小时内联系您。
        </div>
      </div>
    </a-modal> -->
      <!-- 结果查询弹窗 -->
      <a-modal
        v-model:visible="resultShow"
        title="结果查询"
        width="35%"
        :footer="null"
      >
        <div class="success-title">
          恭喜您已完成资料填写,请耐心等待,工作人员会在24小时内联系您。
        </div>
        <div class="success-text">
          <p style="font-weight: bold;">
            如需进一步了解请拨打业务专线进行咨询:
          </p>
          <p>
            <span style="font-size: 16px;font-weight: bold;"
              >数字化转型服务:</span
            ><span style="margin-left: 10px">卢思冰</span
            ><span style="color: #1089ff;margin-left: 5px;">15765999441</span>
          </p>
          <p>
            <span style="font-size: 16px;font-weight: bold;">融资服务:</span>
            <span style="margin-left: 10px">李志伟</span
            ><span style="color: #1089ff;margin-left: 5px;">18245108077</span>
          </p>
          <p>
            <span style="font-size: 16px;font-weight: bold;">政策申请: </span
            ><span style="margin-left: 10px">侯惠宁</span
            ><span style="color: #1089ff;margin-left: 5px;">18745349632</span>
          </p>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getPolicyList } from "../api/financialPrefecture";
import {
  isFinish,
  isFinishResult,
  specialLoanFileAdd,
} from "../api/digitization/index";
export default {
  name: "NumberToWisdom",
  data() {
    return {
      wrapperCol: {
        span: 24,
      },
      content: "",
      visibleShow: false,
      visibleUploadShow: false,
      resultShow: false,
      fileList: [],
      uploadedPaths: [], // 用于存储成功上传的文件路径
      // person: "",
      // phone: "",
      isFlag: {},
      params: {},
      policyList: [],
      reviewStatus: "",
    };
  },
  computed: {
    ...mapGetters([
      "orgId",
      "isAuth",
      "loginType",
      "orgName",
      "isLogin",
      "userId",
    ]),
    // shouldShowDiv() {
    //   // 计算异或逻辑
    //   const countTrue =
    //     (this.isFlag.if_assess == 0 ? 1 : 0) +
    //     (this.isFlag.if_diagnosis == 0 ? 1 : 0) +
    //     (this.isFlag.option3 == true ? 1 : 0);
    //   // 只有当恰好有一个条件为真时才显示
    //   return countTrue == 1;
    // },
  },
  methods: {
    // 未登录显示的登录按钮事件
    handleClickToLogin() {
      this.$router.push("/");
    },
    // 立即申请
    handleClickApply() {
      this.visibleShow = true;
      this.getFlag();
    },
    // 弹窗ok事件
    handleOk() {
      if (!this.content) {
        this.$message.error("请填写方案介绍");
        return false;
      } else if (this.uploadedPaths.length <= 0) {
        this.$message.error("请上传数字化转型方案");
        return false;
      }
      let a = [];
      this.uploadedPaths.forEach((item) => {
        let obj = {};
        obj.file_url = item.response.pd[0].url;
        obj.file_name = item.response.pd[0].name;
        a.push(obj);
      });
      let params = {
        special_loan_info_id: this.params.special_loan_info_id,
        file_type: "3",
        pd: a,
        content: this.content,
        create_by:localStorage.getItem("userName")
      };
      specialLoanFileAdd(params).then((res) => {
        if (res.result == "success") console.log(res);
        this.$message.success("方案提交成功");
        this.visibleUploadShow = false;
        this.getFlag();
      });
    },
    cancel() {
      this.getFlag();
    },
    // 立即诊断
    handleClickDiagnose() {
      if (this.loginType === "qiye" && this.isAuth === "N") {
        this.$message.info("请先完成企业认证或个体认证后才能进入");
        this.$router.push("/PersonalLayout/WareHouse");
        return false;
      } else {
        this.$router.push("/PersonalLayout/InDepthAnalysisReport");
      }
    },
    // 诊断结果
    handleClickResult() {
      this.$router.push("/PersonalLayout/InDepthAnalysisReport");
    },
    // 立即测评按钮事件
    handleClickDigitization() {
      if (this.loginType === "qiye" && this.isAuth === "N") {
        this.$message.info("请先完成企业认证或个体认证后才能进入");
        this.$router.push("/PersonalLayout/WareHouse");
        return false;
      } else {
        this.$router.push("/Digitization");
      }
    },
    // 测评结果
    handleClickTo() {
      this.$router.push({
        path: "/Digitization",
        query: {
          id: this.orgId,
        },
      });
    },
    // 上传方案
    handleClickUploading() {
      this.visibleUploadShow = true;
    },
    beforeUpload(file) {
      const isPDForWord =
        file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      if (!isPDForWord) {
        this.$message.error("上传文件只能是 PDF 或 Word 格式!");
      }
      this.fileList = file;
      return isPDForWord;
    },
    handleChange({ fileList }) {
      this.uploadedPaths = fileList;
    },
    // 下载政策
    handleClickDownload(item) {
      if (item.url) {
        const urls = item.url.split(","); // 将URL字符串分割成数组
        urls.forEach((url) => {
          window.open(url.trim(), "_blank"); // 使用trim()去除可能的空白字符
        });
      } else {
        this.$message.info("无附件");
      }
      // if (item.url) {
      //   const a = item.url.split(",");
      //   console.log(a);
      //   if (a.length > 0) {
      //     a.forEach((i) => {
      //       window.open(i, "_blank");
      //     });
      //   } else {
      //     window.open(item.url, "_blank");
      //   }
      // } else {
      //   this.$message.info("无附件");
      // }
    },
    // 结果查询
    handleClickConsequence() {
      // this.resultShow = true;
      this.visibleShow = true;
      // this.$message.success("恭喜您已完成资料填写,请耐心等待,工作人员会在24小时内联系您。");
    },
    // // 保存联系人
    // handleClickPerson() {
    //   let params = {
    //     enterprise_id: this.orgId,
    //     loan_type: 1,
    //     id: this.isFlag.id,
    //     contact_person: this.person,
    //   };
    //   isFinishResult(params).then((res) => {
    //     if (res.result == "success") this.$message.success("保存成功");
    //   });
    // },
    // // 验证手机号的正则表达式（以中国大陆手机号为例）
    // validatePhoneNumber(phone) {
    //   const regex = /^1[3-9]\d{9}$/;
    //   return regex.test(phone);
    // },
    // 保存联系电话
    // handleClickPhone() {
    //   // 获取输入框中的手机号
    //   const phone = this.phone.trim();
    //   // 验证手机号
    //   if (!this.validatePhoneNumber(phone)) {
    //     // 如果手机号不合法，设置错误信息
    //     this.$message.info("请输入有效的手机号");
    //     return false;
    //   } else {
    //     let params = {
    //       enterprise_id: this.orgId,
    //       loan_type: 1,
    //       id: this.isFlag.id,
    //       contact_phone: this.phone,
    //     };
    //     isFinishResult(params).then((res) => {
    //       if (res.result == "success") this.$message.success("保存成功");
    //     });
    //   }
    // },
    // 查询状态
    getFlag() {
      let params = {
        enterprise_id: this.orgId,
        loan_type: 1,
        // policy_info_id: "",
      };
      isFinish(params).then((res) => {
        console.log(res);

        this.isFlag = res.pd;
        // this.person = this.isFlag.contact_person;
        // this.phone = this.isFlag.contact_phone;
        this.params.special_loan_info_id = res.pd.special_loan_info_id;
        this.reviewStatus = res.pd.review_status;
        console.log(this.reviewStatus);
        console.log(this.params);
      });
    },
    getList() {
      getPolicyList({
        type: 1,
        user_id: this.userId ? this.userId : "null",
      }).then((res) => {
        this.policyList = res.varList;
      });
    },
  },
  mounted() {
    if (this.orgId) {
      this.getFlag();
    }
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.NumberWisdom {
  position: relative;
  // height: 458px;
  width: 1440px;
  margin: 0 auto;
  img {
    // height: 100%;
    // width: 100%;
  }
  .button {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 50px;
    left: 50%;
    margin-left: -90px;
    // transform: translateX(-50%);
    div {
      background: #4095e5;
      color: #fff;
      width: 180px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
      border-radius: 2px;
    }
  }
}
.info {
  margin-top: 20px;
  h2 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  .limit,
  .condition,
  .condition,
  .support {
    box-shadow: 0px 2px 10px #929292;
    padding: 10px 30px;
    font-size: 18px;
    // font-weight: bold;
    width: 90%;
    margin: 0 auto;
    border-radius: 10px;
    p {
      margin-bottom: 10px;
    }
  }
  .lj {
    width: 90%;
    margin: 20px auto;
    text-align: center;
  }
}
.ant-divider-horizontal {
  margin: 12px 0;
}
.item {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
::v-deep .ant-modal-title {
  font-size: 18px !important;
  font-weight: bold !important;
}
.button-wrap {
  p {
    box-shadow: 0px 0px 10px #929292;
    border-radius: 5px;
    cursor: pointer;
    width: 80px;
    text-align: center;
    height: 40px;
    line-height: 40px;
  }
}
.result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}
.success-title {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}
.success-text {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}
</style>
